import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import {
    Container,
    Typography,
    Box,
    AppBar,
    Toolbar,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    CssBaseline,
    IconButton,
    Grid,
    Paper,
    createTheme,
    ThemeProvider
} from '@mui/material';
import { Home, FitnessCenter, School, Menu as MenuIcon } from '@mui/icons-material';
import './Dashboard.css';

// Importe sua logo
import logo from './logo192.png';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement);

const drawerWidth = 240;

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1d1d1d',
        },
        primary: {
            main: '#90caf9',
        },
        text: {
            primary: '#ffffff',
            secondary: '#aaaaaa',
        },
    },
});

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState({
        total_alunos: 0,
        total_exercicios: 0,
        total_treinos: 0,
        usuarios: [],
        exercicios_por_treino: [],
        treinos_por_aluno: [],
        usuarios_por_tipo: [],
    });
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const response = await fetch('https://athixdev.xyz/best/dash.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    throw new Error('Erro na resposta da requisição');
                }
                const data = await response.json();
                setDashboardData(data);
            } catch (error) {
                console.error('Erro ao buscar dados do dashboard:', error);
            }
        };

        fetchDashboardData();
    }, []);

    const drawer = (
        <div>
            {/* Inclua a logo como o primeiro item dentro do drawer */}
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <img src={logo} alt="Logo" style={{ maxHeight: '50px' }} />
            </Box>
            <List>
                <ListItem button component={Link} to="/dashboard">
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button component={Link} to="/alunos">
                    <ListItemIcon>
                        <School />
                    </ListItemIcon>
                    <ListItemText primary="Alunos" />
                </ListItem>
                <ListItem button component={Link} to="/exercicios">
                    <ListItemIcon>
                        <FitnessCenter />
                    </ListItemIcon>
                    <ListItemText primary="Exercícios" />
                </ListItem>
                <ListItem button component={Link} to="/treinos">
                    <ListItemIcon>
                        <FitnessCenter />
                    </ListItemIcon>
                    <ListItemText primary="Treinos" />
                </ListItem>
            </List>
        </div>
    );

    const barData = {
        labels: ['Alunos', 'Exercícios', 'Treinos'],
        datasets: [
            {
                label: 'Total',
                data: [
                    dashboardData.total_alunos,
                    dashboardData.total_exercicios,
                    dashboardData.total_treinos,
                ],
                backgroundColor: ['#ff6384', '#36a2eb', '#cc65fe'],
            },
        ],
    };

    const exerciciosPorTreinoData = {
        labels: dashboardData.exercicios_por_treino.map(item => `Treino ${item.treino_id}`),
        datasets: [
            {
                label: 'Exercícios por Treino',
                data: dashboardData.exercicios_por_treino.map(item => item.total),
                backgroundColor: '#36a2eb',
            },
        ],
    };

    const treinosPorAlunoData = {
        labels: dashboardData.treinos_por_aluno.map(item => `Aluno ${item.aluno_id}`),
        datasets: [
            {
                label: 'Treinos por Aluno',
                data: dashboardData.treinos_por_aluno.map(item => item.total),
                backgroundColor: '#ff6384',
            },
        ],
    };

    const usuariosPorTipoData = {
        labels: dashboardData.usuarios_por_tipo.map(item => item.tipo),
        datasets: [
            {
                label: 'Usuários por Tipo',
                data: dashboardData.usuarios_por_tipo.map(item => item.total),
                backgroundColor: ['#ff6384', '#36a2eb', '#cc65fe'],
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#ffffff', // Cor do texto da legenda
                },
            },
            title: {
                display: true,
                text: 'Estatísticas',
                color: '#ffffff', // Cor do título
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#ffffff', // Cor das labels do eixo x
                },
            },
            y: {
                ticks: {
                    color: '#ffffff', // Cor das labels do eixo y
                },
            },
        },
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        bgcolor: 'primary.main'
                    }}
                >
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            Dashboard
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Melhora a performance de abertura em dispositivos móveis.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Container>
                        <Box mt={5}>
                            <Typography variant="h4" gutterBottom>
                                Resumo do Sistema
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ bgcolor: 'background.paper', p: 2 }}>
                                        <Bar data={barData} options={chartOptions} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ bgcolor: 'background.paper', p: 2 }}>
                                        <Bar data={exerciciosPorTreinoData} options={chartOptions} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ bgcolor: 'background.paper', p: 2 }}>
                                        <Bar data={treinosPorAlunoData} options={chartOptions} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ bgcolor: 'background.paper', p: 2 }}>
                                        <Pie data={usuariosPorTipoData} options={chartOptions} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Dashboard;
