import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    AppBar,
    Toolbar,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    CssBaseline,
    IconButton,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    createTheme,
    ThemeProvider,
    Paper,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Autocomplete,
} from '@mui/material';
import { Home, FitnessCenter, School, Menu as MenuIcon, Close } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import logo from './logo192.png'; // Importe sua logo aqui

const drawerWidth = 240;

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1d1d1d',
        },
        primary: {
            main: '#90caf9',
        },
        text: {
            primary: '#ffffff',
            secondary: '#aaaaaa',
        },
    },
});

const Treino = () => {
    const [alunos, setAlunos] = useState([]);
    const [exercicios, setExercicios] = useState([]);
    const [treinos, setTreinos] = useState([]);
    const [selectedAluno, setSelectedAluno] = useState('');
    const [nomeTreino, setNomeTreino] = useState('');
    const [diaSemana, setDiaSemana] = useState('');
    const [selectedExercicios, setSelectedExercicios] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentTreinoId, setCurrentTreinoId] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        fetchAlunos();
        fetchExercicios();
        fetchTreinos();
    }, []);

    const fetchAlunos = async () => {
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php?acao=getAlunos');
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            setAlunos(data);
        } catch (error) {
            console.error('Erro ao buscar alunos:', error);
        }
    };

    const fetchExercicios = async () => {
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php?acao=getExercicios');
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            setExercicios(data);
        } catch (error) {
            console.error('Erro ao buscar exercícios:', error);
        }
    };

    const fetchTreinos = async () => {
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php?acao=getTreinos');
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            setTreinos(data);
        } catch (error) {
            console.error('Erro ao buscar treinos:', error);
        }
    };

    const handleAlunoChange = (e) => {
        setSelectedAluno(e.target.value);
    };

    const handleNomeTreinoChange = (e) => {
        setNomeTreino(e.target.value);
    };

    const handleDiaSemanaChange = (e) => {
        setDiaSemana(e.target.value);
    };

    const handleExercicioChange = (index, field, value) => {
        setSelectedExercicios((prevExercicios) => {
            const updatedExercicios = [...prevExercicios];
            if (!updatedExercicios[index]) {
                updatedExercicios[index] = {};
            }
            updatedExercicios[index][field] = value;
            return updatedExercicios;
        });
    };

    const handleExercicioSelect = (index, value) => {
        handleExercicioChange(index, 'id', value ? value.id : '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const treino = {
            aluno_id: selectedAluno,
            nome: nomeTreino,
            dia_semana: diaSemana,
            exercicios: selectedExercicios,
        };
        const method = editMode ? 'editTreino' : 'addTreino';
        if (editMode) {
            treino.id = currentTreinoId;
        }
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...treino, acao: method }),
            });
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            if (data.status === 'sucesso') {
                alert('Treino salvo com sucesso!');
                setModalOpen(false);
                setEditMode(false);
                setSelectedAluno('');
                setNomeTreino('');
                setDiaSemana('');
                setSelectedExercicios([]);
                setCurrentTreinoId(null);
                fetchTreinos();
            } else {
                alert('Erro ao salvar treino');
            }
        } catch (error) {
            console.error('Erro ao salvar treino:', error);
            alert('Erro ao salvar treino. Tente novamente mais tarde.');
        }
    };

    const handleEdit = (treino) => {
        setSelectedAluno(treino.aluno_id);
        setNomeTreino(treino.nome);
        setDiaSemana(treino.dia_semana);
        setSelectedExercicios(JSON.parse(treino.exercicios) || []);
        setCurrentTreinoId(treino.id);
        setEditMode(true);
        setModalOpen(true);
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ acao: 'deleteTreino', id }),
            });
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            if (data.status === 'sucesso') {
                fetchTreinos();
            } else {
                alert('Erro ao deletar treino');
            }
        } catch (error) {
            console.error('Erro ao deletar treino:', error);
            alert('Erro ao deletar treino. Tente novamente mais tarde.');
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const addExercicio = () => {
        setSelectedExercicios((prevExercicios) => [...prevExercicios, {}]);
    };

    const openModalForNewTreino = () => {
        setSelectedAluno('');
        setNomeTreino('');
        setDiaSemana('');
        setSelectedExercicios([]);
        setCurrentTreinoId(null);
        setEditMode(false);
        setModalOpen(true);
    };

    const drawer = (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <img src={logo} alt="Logo" style={{ maxHeight: '50px' }} />
            </Box>
            <List>
                <ListItem button component={Link} to="/dashboard">
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button component={Link} to="/alunos">
                    <ListItemIcon>
                        <School />
                    </ListItemIcon>
                    <ListItemText primary="Alunos" />
                </ListItem>
                <ListItem button component={Link} to="/exercicios">
                    <ListItemIcon>
                        <FitnessCenter />
                    </ListItemIcon>
                    <ListItemText primary="Exercícios" />
                </ListItem>
                <ListItem button component={Link} to="/treinos">
                    <ListItemIcon>
                        <FitnessCenter />
                    </ListItemIcon>
                    <ListItemText primary="Treinos" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        bgcolor: 'primary.main'
                    }}
                >
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            Treinos
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Melhora a performance de abertura em dispositivos móveis.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Container>
                        <Box mt={5}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                Treinos
                            </Typography>
                            <Button variant="contained" color="primary" onClick={openModalForNewTreino}>
                                Novo Treino
                            </Button>
                            <TableContainer component={Paper} sx={{ mt: 3 }}>
                                <Table sx={{ minWidth: 650 }} aria-label="treinos table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Aluno</TableCell>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>Dia da Semana</TableCell>
                                            <TableCell>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {treinos.map((treino) => (
                                            <TableRow key={treino.id}>
                                                <TableCell>{alunos.find((aluno) => aluno.id === treino.aluno_id)?.nome}</TableCell>
                                                <TableCell>{treino.nome}</TableCell>
                                                <TableCell>{treino.dia_semana}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => handleEdit(treino)}
                                                        sx={{ mr: 1 }}
                                                    >
                                                        Editar
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => handleDelete(treino.id)}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Container>
                </Box>
                <Dialog
                    open={modalOpen}
                    onClose={() => {
                        setModalOpen(false);
                        setEditMode(false);
                        setCurrentTreinoId(null);
                    }}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>
                        {editMode ? 'Editar Treino' : 'Novo Treino'}
                        <IconButton
                            aria-label="close"
                            onClick={() => setModalOpen(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputLabel id="aluno-label">Aluno</InputLabel>
                                <Select
                                    labelId="aluno-label"
                                    id="aluno"
                                    value={selectedAluno}
                                    label="Aluno"
                                    onChange={handleAlunoChange}
                                    sx={{ bgcolor: 'background.paper' }}
                                >
                                    {alunos.map((aluno) => (
                                        <MenuItem key={aluno.id} value={aluno.id}>
                                            {aluno.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                id="nome-treino"
                                label="Nome do Treino"
                                variant="outlined"
                                fullWidth
                                sx={{ mt: 2, bgcolor: 'background.paper' }}
                                value={nomeTreino}
                                onChange={handleNomeTreinoChange}
                            />
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="dia-semana-label">Dia da Semana</InputLabel>
                                <Select
                                    labelId="dia-semana-label"
                                    id="dia-semana"
                                    value={diaSemana}
                                    label="Dia da Semana"
                                    onChange={handleDiaSemanaChange}
                                    sx={{ bgcolor: 'background.paper' }}
                                >
                                    <MenuItem value="Segunda-feira">Segunda-feira</MenuItem>
                                    <MenuItem value="Terça-feira">Terça-feira</MenuItem>
                                    <MenuItem value="Quarta-feira">Quarta-feira</MenuItem>
                                    <MenuItem value="Quinta-feira">Quinta-feira</MenuItem>
                                    <MenuItem value="Sexta-feira">Sexta-feira</MenuItem>
                                    <MenuItem value="Sábado">Sábado</MenuItem>
                                    <MenuItem value="Domingo">Domingo</MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={addExercicio}
                                sx={{ mt: 2 }}
                            >
                                Adicionar Exercício
                            </Button>
                            {selectedExercicios.map((exercicio, index) => (
                                <Box key={index} sx={{ mt: 2, border: '1px solid #ccc', borderRadius: 1, p: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Autocomplete
                                                id={`exercicio-${index}`}
                                                options={exercicios}
                                                getOptionLabel={(option) => option.nome}
                                                value={exercicios.find((ex) => ex.id === exercicio.id) || null}
                                                onChange={(e, value) => handleExercicioSelect(index, value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Buscar Exercício"
                                                        variant="outlined"
                                                        fullWidth
                                                        sx={{ bgcolor: 'background.paper' }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField
                                                id={`carga-${index}`}
                                                label="Carga"
                                                variant="outlined"
                                                fullWidth
                                                value={exercicio.carga || ''}
                                                onChange={(e) => handleExercicioChange(index, 'carga', e.target.value)}
                                                sx={{ bgcolor: 'background.paper' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField
                                                id={`repeticoes-${index}`}
                                                label="Repetições"
                                                variant="outlined"
                                                fullWidth
                                                value={exercicio.repeticoes || ''}
                                                onChange={(e) => handleExercicioChange(index, 'repeticoes', e.target.value)}
                                                sx={{ bgcolor: 'background.paper' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField
                                                id={`peso-${index}`}
                                                label="Peso"
                                                variant="outlined"
                                                fullWidth
                                                value={exercicio.peso || ''}
                                                onChange={(e) => handleExercicioChange(index, 'peso', e.target.value)}
                                                sx={{ bgcolor: 'background.paper' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id={`observacao-${index}`}
                                                label="Observação"
                                                variant="outlined"
                                                fullWidth
                                                value={exercicio.observacao || ''}
                                                onChange={(e) => handleExercicioChange(index, 'observacao', e.target.value)}
                                                sx={{ bgcolor: 'background.paper' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                            <DialogActions>
                                <Button onClick={() => setModalOpen(false)} color="secondary">Cancelar</Button>
                                <Button type="submit" variant="contained" color="primary">
                                    {editMode ? 'Salvar Alterações' : 'Adicionar Treino'}
                                </Button>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </Box>
        </ThemeProvider>
    );
};

export default Treino;
