import React, { useState, useEffect } from 'react';
import { Container, Box, AppBar, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, IconButton, Button, createTheme, ThemeProvider, Paper, Typography, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Home, FitnessCenter, School, Menu as MenuIcon, Close } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import logo from './logo192.png'; // Importe sua logo aqui
import './Exercicios.css';

const drawerWidth = 240;

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1d1d1d',
        },
        primary: {
            main: '#90caf9',
        },
        text: {
            primary: '#ffffff',
            secondary: '#aaaaaa',
        },
    },
});

const Exercicios = () => {
    const [exercicios, setExercicios] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentExercicio, setCurrentExercicio] = useState({
        id: null,
        treino_id: '',
        nome: '',
        carga: '',
        repeticoes: '',
        observacao: '',
        video_url: ''
    });

    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        fetchExercicios();
    }, []);

    const fetchExercicios = async () => {
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php?acao=getExercicios');
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            setExercicios(data);
        } catch (error) {
            console.error('Erro ao buscar exercícios:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentExercicio({ ...currentExercicio, [name]: value });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const method = editMode ? 'editExercicio' : 'addExercicio';
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...currentExercicio, acao: method }),
            });
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            if (data.status === 'sucesso') {
                fetchExercicios();
                setModalOpen(false);
                setEditMode(false);
                setCurrentExercicio({
                    id: null,
                    treino_id: '',
                    nome: '',
                    carga: '',
                    repeticoes: '',
                    observacao: '',
                    video_url: ''
                });
            } else {
                alert('Erro ao salvar exercício');
            }
        } catch (error) {
            console.error('Erro ao salvar exercício:', error);
            alert('Erro ao salvar exercício. Tente novamente mais tarde.');
        }
    };

    const handleEdit = (exercicio) => {
        setCurrentExercicio(exercicio);
        setEditMode(true);
        setModalOpen(true);
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ acao: 'deleteExercicio', id }),
            });
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            if (data.status === 'sucesso') {
                fetchExercicios();
            } else {
                alert('Erro ao deletar exercício');
            }
        } catch (error) {
            console.error('Erro ao deletar exercício:', error);
            alert('Erro ao deletar exercício. Tente novamente mais tarde.');
        }
    };

    const filteredExercicios = exercicios.filter(exercicio =>
        exercicio.nome.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            {/* Inclua a logo como o primeiro item dentro do drawer */}
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <img src={logo} alt="Logo" style={{ maxHeight: '50px' }} />
            </Box>
            <List>
                <ListItem button component={Link} to="/dashboard">
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button component={Link} to="/alunos">
                    <ListItemIcon>
                        <School />
                    </ListItemIcon>
                    <ListItemText primary="Alunos" />
                </ListItem>
                <ListItem button component={Link} to="/exercicios">
                    <ListItemIcon>
                        <FitnessCenter />
                    </ListItemIcon>
                    <ListItemText primary="Exercícios" />
                </ListItem>
                <ListItem button component={Link} to="/treinos">
                    <ListItemIcon>
                        <FitnessCenter />
                    </ListItemIcon>
                    <ListItemText primary="Treinos" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        bgcolor: 'primary.main'
                    }}
                >
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            Exercícios
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Melhora a performance de abertura em dispositivos móveis.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Container>
                        <Box mt={5}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                Exercícios
                            </Typography>
                            <TextField
                                label="Buscar exercício"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                sx={{ bgcolor: 'background.paper' }}
                            />
                            <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
                                Adicionar Exercício
                            </Button>
                            <Box mt={2}>
                                <Paper elevation={3} sx={{ bgcolor: 'background.paper', p: 2 }}>
                                    <table className="exercicios-table">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Carga</th>
                                                <th>Repetições</th>
                                                <th>Observação</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredExercicios.map((exercicio) => (
                                                <tr key={exercicio.id}>
                                                    <td>{exercicio.nome}</td>
                                                    <td>{exercicio.carga}</td>
                                                    <td>{exercicio.repeticoes}</td>
                                                    <td>{exercicio.observacao}</td>
                                                    <td>
                                                        <Button onClick={() => handleEdit(exercicio)}>Editar</Button>
                                                        <Button onClick={() => handleDelete(exercicio.id)}>Deletar</Button>
                                                        <Button href={exercicio.video_url} target="_blank" rel="noopener noreferrer">Assistir</Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Paper>
                            </Box>
                        </Box>
                    </Container>
                </Box>
                <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md" fullWidth>
                    <DialogTitle>
                        {editMode ? 'Editar Exercício' : 'Adicionar Exercício'}
                        <IconButton
                            aria-label="close"
                            onClick={() => setModalOpen(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                label="Nome"
                                name="nome"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={currentExercicio.nome}
                                onChange={handleInputChange}
                                required
                                sx={{ bgcolor: 'background.paper' }}
                            />
                            <TextField
                                label="Carga"
                                name="carga"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type="number"
                                value={currentExercicio.carga}
                                onChange={handleInputChange}
                                sx={{ bgcolor: 'background.paper' }}
                            />
                            <TextField
                                label="Repetições"
                                name="repeticoes"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type="number"
                                value={currentExercicio.repeticoes}
                                onChange={handleInputChange}
                                sx={{ bgcolor: 'background.paper' }}
                            />
                            <TextField
                                label="Observação"
                                name="observacao"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                multiline
                                rows={4}
                                value={currentExercicio.observacao}
                                onChange={handleInputChange}
                                sx={{ bgcolor: 'background.paper' }}
                            />
                            <TextField
                                label="URL do Vídeo"
                                name="video_url"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={currentExercicio.video_url}
                                onChange={handleInputChange}
                                sx={{ bgcolor: 'background.paper' }}
                            />
                            <DialogActions>
                                <Button onClick={() => setModalOpen(false)} color="secondary">Cancelar</Button>
                                <Button type="submit" variant="contained" color="primary">
                                    {editMode ? 'Salvar Alterações' : 'Adicionar Exercício'}
                                </Button>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </Box>
        </ThemeProvider>
    );
};

export default Exercicios;
