import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Alunos from './components/Alunos/Alunos'; // Certifique-se de ter esses componentes
import Exercicios from './components/Exercicios/Exercicios';
import Treinos from './components/Treinos/Treinos';

const App = () => {
    const [usuario, setUsuario] = useState(null);

    const handleLogin = (usuario) => {
        setUsuario(usuario);
    };

    return (
        <Router>
            <div className="App">
                {usuario ? (
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard usuario={usuario} />} />
                        <Route path="/alunos" element={<Alunos />} />
                        <Route path="/exercicios" element={<Exercicios />} />
                        <Route path="/treinos" element={<Treinos />} />
                        <Route path="*" element={<Dashboard usuario={usuario} />} />
                    </Routes>
                ) : (
                    <Login onLogin={handleLogin} />
                )}
            </div>
        </Router>
    );
};

export default App;
