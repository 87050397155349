import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    InputAdornment,
    Box,
    AppBar,
    Toolbar,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    CssBaseline,
    createTheme,
    ThemeProvider
} from '@mui/material';
import { Add, Edit, Delete, Search, Home, FitnessCenter, School, Menu as MenuIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import logo from './logo192.png'; // Importe sua logo aqui
import './Alunos.css';

const drawerWidth = 240;

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1d1d1d',
        },
        primary: {
            main: '#90caf9',
        },
        text: {
            primary: '#ffffff',
            secondary: '#aaaaaa',
        },
    },
});

const Alunos = () => {
    const [alunos, setAlunos] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentAluno, setCurrentAluno] = useState({ id: '', nome: '', email: '', senha: '', tipo: 'aluno' });
    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        fetchAlunos();
    }, []);

    const fetchAlunos = async () => {
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php?acao=getAlunos');
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            setAlunos(data);
        } catch (error) {
            console.error('Erro ao buscar alunos:', error);
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredAlunos = alunos.filter(aluno =>
        aluno.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        aluno.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleAddAluno = () => {
        setEditMode(false);
        setCurrentAluno({ id: '', nome: '', email: '', senha: '', tipo: 'aluno' });
        setModalOpen(true);
    };

    const handleEdit = (aluno) => {
        setEditMode(true);
        setCurrentAluno(aluno);
        setModalOpen(true);
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ acao: 'deleteAluno', id }),
            });
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            if (data.status === 'sucesso') {
                fetchAlunos();
            } else {
                alert('Erro ao deletar aluno');
            }
        } catch (error) {
            console.error('Erro ao deletar aluno:', error);
            alert('Erro ao deletar aluno. Tente novamente mais tarde.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const method = editMode ? 'editAluno' : 'addAluno';
        try {
            const response = await fetch('https://athixdev.xyz/best/api.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...currentAluno, acao: method }),
            });
            if (!response.ok) {
                throw new Error('Erro na resposta da requisição');
            }
            const data = await response.json();
            if (data.status === 'sucesso') {
                alert('Aluno salvo com sucesso!');
                setModalOpen(false);
                fetchAlunos();
            } else {
                alert('Erro ao salvar aluno');
            }
        } catch (error) {
            console.error('Erro ao salvar aluno:', error);
            alert('Erro ao salvar aluno. Tente novamente mais tarde.');
        }
    };

    const handleChange = (e) => {
        setCurrentAluno({ ...currentAluno, [e.target.name]: e.target.value });
    };

    const drawer = (
        <div>
            {/* Inclua a logo como o primeiro item dentro do drawer */}
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <img src={logo} alt="Logo" style={{ maxHeight: '50px' }} />
            </Box>
            <List>
                <ListItem button component={Link} to="/dashboard">
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button component={Link} to="/alunos">
                    <ListItemIcon>
                        <School />
                    </ListItemIcon>
                    <ListItemText primary="Alunos" />
                </ListItem>
                <ListItem button component={Link} to="/exercicios">
                    <ListItemIcon>
                        <FitnessCenter />
                    </ListItemIcon>
                    <ListItemText primary="Exercícios" />
                </ListItem>
                <ListItem button component={Link} to="/treinos">
                    <ListItemIcon>
                        <FitnessCenter />
                    </ListItemIcon>
                    <ListItemText primary="Treinos" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        bgcolor: 'primary.main'
                    }}
                >
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            Alunos
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Melhora a performance de abertura em dispositivos móveis.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Toolbar />
                    <Container>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Alunos
                        </Typography>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                            <TextField
                                label="Pesquisar Aluno"
                                variant="outlined"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ bgcolor: 'background.paper' }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Add />}
                                onClick={handleAddAluno}
                            >
                                Adicionar Aluno
                            </Button>
                        </Box>
                        <TableContainer component={Paper} sx={{ bgcolor: 'background.paper' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell align="right">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredAlunos.map(aluno => (
                                        <TableRow key={aluno.id}>
                                            <TableCell>{aluno.nome}</TableCell>
                                            <TableCell>{aluno.email}</TableCell>
                                            <TableCell align="right">
                                                <IconButton color="primary" onClick={() => handleEdit(aluno)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton color="secondary" onClick={() => handleDelete(aluno.id)}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </Box>
                <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>{editMode ? 'Editar Aluno' : 'Adicionar Aluno'}</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="nome"
                                name="nome"
                                label="Nome"
                                type="text"
                                fullWidth
                                required
                                value={currentAluno.nome}
                                onChange={handleChange}
                                sx={{ bgcolor: 'background.paper' }}
                            />
                            <TextField
                                margin="dense"
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                fullWidth
                                required
                                value={currentAluno.email}
                                onChange={handleChange}
                                sx={{ bgcolor: 'background.paper' }}
                            />
                            {!editMode && (
                                <TextField
                                    margin="dense"
                                    id="senha"
                                    name="senha"
                                    label="Senha"
                                    type="password"
                                    fullWidth
                                    required
                                    value={currentAluno.senha}
                                    onChange={handleChange}
                                    sx={{ bgcolor: 'background.paper' }}
                                />
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setModalOpen(false)}>Cancelar</Button>
                            <Button type="submit" variant="contained" color="primary">
                                {editMode ? 'Salvar' : 'Adicionar'}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Box>
        </ThemeProvider>
    );
};

export default Alunos;
